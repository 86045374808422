(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/wager-payment-flow/marketplace-flow/assets/javascripts/marketplace-middlewares-setup.js') >= 0) return;  svs.modules.push('/components/wager-payment-flow/marketplace-flow/assets/javascripts/marketplace-middlewares-setup.js');
"use strict";


const {
  dialog
} = svs.components;
const {
  Confirm,
  branding,
  type,
  message
} = dialog;
const {
  marketplaceShares,
  marketplaceBalance,
  paymentConfirm
} = svs.components.payment.middleware;
const {
  paymentDone,
  utils: paymentDoneUtils
} = svs.components.payment.middleware.paymentDone;
const {
  groupTypes
} = svs.components.marketplaceData.constants;
const {
  Currency: currency
} = svs.utils.format;

const marketplaceSharesMW = marketplaceShares(marketplaceSharesContext => new Promise((resolve, reject) => {
  const {
    numberOfNewShares,
    balance
  } = marketplaceSharesContext;
  const title = "".concat(numberOfNewShares, " ").concat(numberOfNewShares > 1 ? 'andelar' : 'andel', " till har k\xF6pts!");
  dialog.api.add(new Confirm({
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'balance',
    title,
    message: [{
      type: message.TEXT,
      text: ["Det finns nu ".concat(currency(balance), " kr att spela f\xF6r."), 'Du kan gå tillbaka och ändra raden eller fortsätta till betalningen'].join('<br />')
    }],
    actions: [{
      title: 'Avbryt',
      showLoader: true,
      callback() {
        dialog.api.close();
        reject(new Error('Abort payment flow clicked, new shares bought.'));
      }
    }, {
      title: 'Fortsätt till betalning',
      showLoader: true,
      callback() {
        dialog.api.close();
        resolve();
      }
    }]
  }));
}));
const marketplaceBalanceMW = marketplaceBalance(marketplaceBalanceContext => new Promise(resolve => {
  const {
    messages,
    title
  } = marketplaceBalanceContext;
  dialog.api.add(new Confirm({
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'balance',
    title,
    message: [{
      type: message.TEXT,
      text: [messages[0], messages[1]].join('<br />')
    }],
    actions: [{
      title: 'Okej',
      showLoader: true,
      callback() {
        dialog.api.close();
        resolve();
      }
    }]
  }));
}));
const paymentConfirmMW = paymentConfirm(paymentContext => new Promise((resolve, reject) => {
  const {
    title,
    role
  } = paymentContext;
  let text = '';
  if (role) {
    text += "Kvarvarande pengar \xE5terbetalas till medlemmarna. <br /> ".concat(role === groupTypes.PRIVATE ? '<br />' : '');
  }
  dialog.api.add(new Confirm({
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'nav-games',
    title,
    message: [{
      type: message.TEXT,
      text
    }],
    actions: [{
      title: 'Avbryt',
      showLoader: true,
      callback() {
        dialog.api.close();
        reject();
      }
    }, {
      title: 'Betala',
      showLoader: true,
      callback() {
        dialog.api.close();
        resolve();
      }
    }]
  }));
}));
const paymentDoneSuccess = paymentDoneContext => new Promise(resolve => {
  const {
    title,
    message: dialogMessage,
    mybetsUrl,
    isRetailer,
    onCallback
  } = paymentDoneContext;
  const message = [];
  const actions = [];
  if (isRetailer) {
    actions.push({
      title: 'Okej',
      callback() {
        dialog.api.close();
        resolve();
      },
      showLoader: true
    });
  } else {
    actions.push({
      title: 'Mina spel',
      callback: () => {
        onCallback(paymentDoneUtils.TRACKING_ACTIONS.MY_GAMES);
        dialog.api.close();
        location.href = mybetsUrl;
      },
      showLoader: true
    }, {
      title: 'Okej',
      callback: () => {
        onCallback(paymentDoneUtils.TRACKING_ACTIONS.OK);
        dialog.api.close();
        resolve();
      },
      showLoader: true
    });
  }
  dialog.api.add(new Confirm({
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'nav-games',
    title,
    message: [{
      type: message.TEXT,
      text: dialogMessage
    }],
    actions
  }));
});
const paymentDoneFailure = paymentDoneContext => {
  const {
    clientErrors,
    clientCode
  } = paymentDoneContext;
  svs.components.wagerPaymentFlow.paymentFailure.paymentFailureDialog(clientErrors, clientCode);
};
const paymentDoneMW = paymentDone(paymentDoneSuccess, paymentDoneFailure);
setGlobal('svs.components.wagerPaymentFlow.marketplaceFlow.middlewareSetup', {
  marketplaceSharesMW,
  marketplaceBalanceMW,
  paymentConfirmMW,
  paymentDoneMW
});

 })(window);