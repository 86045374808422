(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/wager-payment-flow/marketplace-flow/assets/javascripts/marketplace-payment-flow.js') >= 0) return;  svs.modules.push('/components/wager-payment-flow/marketplace-flow/assets/javascripts/marketplace-payment-flow.js');
"use strict";


const {
  createPayment
} = svs.components.payment.payment;
const {
  grassroots
} = svs.components.payment.middleware;
const {
  authenticated
} = svs.components.payment.middleware.authenticated;
const {
  marketplaceSharesMW,
  paymentConfirmMW,
  marketplaceBalanceMW,
  paymentDoneMW
} = svs.components.wagerPaymentFlow.marketplaceFlow.middlewareSetup;
const {
  WagerService
} = svs.components.payment.serviceWager;

const createMarketplacePaymentFlow = _ref => {
  let {
    config,
    wagers,
    isC2C = false
  } = _ref;
  const paymentBuilder = createPayment(new WagerService(config)).addPayable(...wagers).addPreMiddleware(authenticated).addPreMiddleware(marketplaceBalanceMW).addPreMiddleware(marketplaceSharesMW).addPreMiddleware(paymentConfirmMW).addPostMiddleware(paymentDoneMW);
  if (isC2C) {
    paymentBuilder.addPostMiddleware(grassroots);
  }
  return paymentBuilder;
};
setGlobal('svs.components.wagerPaymentFlow.marketplaceFlow.createMarketplacePaymentFlow', createMarketplacePaymentFlow);

 })(window);